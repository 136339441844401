import { QuestionCategory } from 'shared/constants/header'

import { Screen, ScreenId } from './types'

export const screensConfig: { [key in ScreenId]: Screen } = {
  // start screens
  start_age: {
    id: 'start_age',
    url: '/start_age',
    config: {
      step: false,
    },
  },

  // onboarding screens
  /* PLOP_ONBOARDING_SCREENS */
  ob_adhd_graph: {
    id: 'ob_adhd_graph',
    url: '/ob_adhd_graph',
    config: {},
  },

  ob_weight_gain_feedback_adhd: {
    id: 'ob_weight_gain_feedback_adhd',
    url: '/ob_weight_gain_feedback_adhd',
    config: {},
  },

  feedback_summary_adhd: {
    id: 'feedback_summary_adhd',
    url: '/feedback_summary_adhd',
    config: {},
  },

  ob_feedback_sum_adhd: {
    id: 'ob_feedback_sum_adhd',
    url: '/ob_feedback_sum_adhd',
    config: {
      step: true,
    },
  },

  ob_email_adhd: {
    id: 'ob_email_adhd',
    url: '/ob_email_adhd',
    config: {},
  },

  ob_adhd_impulsivity: {
    id: 'ob_adhd_impulsivity',
    url: '/ob_adhd_impulsivity',
    config: {},
  },

  ob_add_goal_1: {
    id: 'ob_add_goal_1',
    url: '/ob_add_goal_1',
    config: {
      step: true,
    },
  },

  ob_add_improve: {
    id: 'ob_add_improve',
    url: '/ob_add_improve',
    config: {
      step: true,
    },
  },

  ob_adhd_statement_8: {
    id: 'ob_adhd_statement_8',
    url: '/ob_adhd_statement_8',
    config: {
      step: true,
    },
  },
  ob_adhd_statement_7: {
    id: 'ob_adhd_statement_7',
    url: '/ob_adhd_statement_7',
    config: {
      step: true,
    },
  },
  ob_adhd_statement_6: {
    id: 'ob_adhd_statement_6',
    url: '/ob_adhd_statement_6',
    config: {
      step: true,
    },
  },

  ob_adhd_statement_5: {
    id: 'ob_adhd_statement_5',
    url: '/ob_adhd_statement_5',
    config: {
      step: true,
    },
  },

  ob_adhd_statement_4: {
    id: 'ob_adhd_statement_4',
    url: '/ob_adhd_statement_4',
    config: {
      step: true,
    },
  },

  start_gender_type: {
    id: 'start_gender_type',
    url: '/start_gender_type',
    config: {},
  },

  start_gender_adhd_overeat_1: {
    id: 'start_gender_adhd_overeat_1',
    url: '/start_gender_adhd_overeat_1',
    config: {},
  },

  start_gender_adhd_overeat: {
    id: 'start_gender_adhd_overeat',
    url: '/start_gender_adhd_overeat',
    config: {},
  },

  start_gender_stress: {
    id: 'start_gender_stress',
    url: '/start_gender_stress',
    config: {},
  },

  ob_adhd_evidence: {
    id: 'ob_adhd_evidence',
    url: '/ob_adhd_evidence',
    config: {},
  },

  ob_adhd_inattention: {
    id: 'ob_adhd_inattention',
    url: '/ob_adhd_inattention',
    config: {},
  },

  ob_adhd_quiz: {
    id: 'ob_adhd_quiz',
    url: '/ob_adhd_quiz',
    config: {},
  },

  ob_about_adhd: {
    id: 'ob_about_adhd',
    url: '/ob_about_adhd',
    config: {},
  },

  ob_adhd_statement_3: {
    id: 'ob_adhd_statement_3',
    url: '/ob_adhd_statement_3',
    config: {
      step: true,
    },
  },

  ob_adhd_statement_2: {
    id: 'ob_adhd_statement_2',
    url: '/ob_adhd_statement_2',
    config: {
      step: true,
    },
  },

  ob_adhd_statement_1: {
    id: 'ob_adhd_statement_1',
    url: '/ob_adhd_statement_1',
    config: {
      step: true,
    },
  },

  ob_adhd_suspect: {
    id: 'ob_adhd_suspect',
    url: '/ob_adhd_suspect',
    config: {
      step: true,
    },
  },

  ob_goals_mix_adhd: {
    id: 'ob_goals_mix_adhd',
    url: '/ob_goals_mix_adhd',
    config: {
      step: true,
    },
  },

  ob_adhd_reason: {
    id: 'ob_adhd_reason',
    url: '/ob_adhd_reason',
    config: {
      step: true,
    },
  },

  ob_adhd_believe: {
    id: 'ob_adhd_believe',
    url: '/ob_adhd_believe',
    config: {
      step: true,
    },
  },

  ob_do_know_adhd: {
    id: 'ob_do_know_adhd',
    url: '/ob_do_know_adhd',
    config: {
      step: true,
    },
  },

  start_gender_sugar: {
    id: 'start_gender_sugar',
    url: '/start_gender_sugar',
    config: {},
  },

  start_gender_adhd: {
    id: 'start_gender_adhd',
    url: '/start_gender_adhd',
    config: {},
  },

  start_gender_emotions: {
    id: 'start_gender_emotions',
    url: '/start_gender_emotions',
    config: {},
  },

  start_gender_eating_type: {
    id: 'start_gender_eating_type',
    url: '/start_gender_eating_type',
    config: {},
  },

  start_gender_chakra_v1: {
    id: 'start_gender_chakra_v1',
    url: '/start_gender_chakra_v1',
    config: {},
  },

  ob_manipura: {
    id: 'ob_manipura',
    url: '/ob_manipura',
    config: {},
  },
  ob_svadhishthana: {
    id: 'ob_svadhishthana',
    url: '/ob_svadhishthana',
    config: {},
  },
  ob_muldhara: {
    id: 'ob_muldhara',
    url: '/ob_muldhara',
    config: {},
  },
  ob_vishuddha: {
    id: 'ob_vishuddha',
    url: '/ob_vishuddha',
    config: {},
  },

  ob_chakra_results: {
    id: 'ob_chakra_results',
    url: '/ob_chakra_results',
    config: {},
  },

  ob_motivational_blocker: {
    id: 'ob_motivational_blocker',
    url: '/ob_motivational_blocker',
    config: {},
  },

  ob_childhood_t_blocker: {
    id: 'ob_childhood_t_blocker',
    url: '/ob_childhood_t_blocker',
    config: {},
  },

  ob_psycological_blocker: {
    id: 'ob_psycological_blocker',
    url: '/ob_psycological_blocker',
    config: {},
  },

  ob_metabolical_blocker: {
    id: 'ob_metabolical_blocker',
    url: '/ob_metabolical_blocker',
    config: {},
  },

  start_gender_chakra: {
    id: 'start_gender_chakra',
    url: '/start_gender_chakra',
    config: {},
  },

  ob_back_issue_config: {
    id: 'ob_back_issue_config',
    url: '/ob_back_issue_config',
    config: {
      step: true,
    },
  },

  start_age_chakra: {
    id: 'start_age_chakra',
    url: '/start_age_chakra',
    config: {
      step: true,
    },
  },

  ob_good_news: {
    id: 'ob_good_news',
    url: '/ob_good_news',
    config: {
      step: true,
    },
  },

  ob_eating_bh: {
    id: 'ob_eating_bh',
    url: '/ob_eating_bh',
    config: {
      step: true,
    },
  },

  ob_graph_yoyo: {
    id: 'ob_graph_yoyo',
    url: '/ob_graph_yoyo',
    config: {
      step: true,
    },
  },

  ob_experts: {
    id: 'ob_experts',
    url: '/ob_experts',
    config: {
      step: true,
    },
  },

  ob_goal_plan: {
    id: 'ob_goal_plan',
    url: '/ob_goal_plan',
    config: {
      step: true,
    },
  },

  ob_med_proof: {
    id: 'ob_med_proof',
    url: '/ob_med_proof',
    config: {
      step: true,
    },
  },

  ob_no_pills: {
    id: 'ob_no_pills',
    url: '/ob_no_pills',
    config: {
      step: true,
    },
  },

  ob_graph_childhood_trauma: {
    id: 'ob_graph_childhood_trauma',
    url: '/ob_graph_childhood_trauma',
    config: {
      step: true,
    },
  },

  ob_associations_intro: {
    id: 'ob_associations_intro',
    url: '/ob_associations_intro',
    config: {
      step: true,
    },
  },

  ob_overcome_eating: {
    id: 'ob_overcome_eating',
    url: '/ob_overcome_eating',
    config: {
      step: true,
    },
  },

  ob_feedback_human: {
    id: 'ob_feedback_human',
    url: '/ob_feedback_human',
    config: {
      step: true,
    },
  },

  ob_map: {
    id: 'ob_map',
    url: '/ob_map',
    config: {
      step: true,
    },
  },

  ob_habitual_journey: {
    id: 'ob_habitual_journey',
    url: '/ob_habitual_journey',
    config: {
      step: true,
    },
  },

  ob_info_dieting: {
    id: 'ob_info_dieting',
    url: '/ob_info_dieting',
    config: {
      step: true,
    },
  },

  ob_graph_prediction_event: {
    id: 'ob_graph_prediction_event',
    url: '/ob_graph_prediction_event',
    config: {
      step: true,
    },
  },

  ob_psychological: {
    id: 'ob_psychological',
    url: '/ob_psychological',
    config: {
      step: true,
    },
  },

  ob_see_3: {
    id: 'ob_see_3',
    url: '/ob_see_3',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_see_2: {
    id: 'ob_see_2',
    url: '/ob_see_2',
    config: {
      step: true,
    },
  },

  ob_see_1: {
    id: 'ob_see_1',
    url: '/ob_see_1',
    config: {
      step: true,
    },
  },

  ob_add_goal: {
    id: 'ob_add_goal',
    url: '/ob_add_goal',
    config: {
      step: true,
    },
  },

  ob_explore_add: {
    id: 'ob_explore_add',
    url: '/ob_explore_add',
    config: {
      step: true,
    },
  },

  ob_snack_time: {
    id: 'ob_snack_time',
    url: '/ob_snack_time',
    config: {
      step: true,
    },
  },

  ob_goal_mind: {
    id: 'ob_goal_mind',
    url: '/ob_goal_mind',
    config: {
      step: true,
    },
  },

  ob_sure_weightloss: {
    id: 'ob_sure_weightloss',
    url: '/ob_sure_weightloss',
    config: {
      step: true,
    },
  },

  ob_motivation_change: {
    id: 'ob_motivation_change',
    url: '/ob_motivation_change',
    config: {
      step: true,
    },
  },

  ob_recommended_medical: {
    id: 'ob_recommended_medical',
    url: '/ob_recommended_medical',
    config: {
      step: true,
    },
  },

  ob_previous_mental_health: {
    id: 'ob_previous_mental_health',
    url: '/ob_previous_mental_health',
    config: {
      step: true,
    },
  },

  ob_previous_attempts: {
    id: 'ob_previous_attempts',
    url: '/ob_previous_attempts',
    config: {
      step: true,
    },
  },

  ob_statement_3: {
    id: 'ob_statement_3',
    url: '/ob_statement_3',
    config: {
      step: true,
    },
  },

  ob_statement_2: {
    id: 'ob_statement_2',
    url: '/ob_statement_2',
    config: {
      step: true,
    },
  },

  ob_statement_1: {
    id: 'ob_statement_1',
    url: '/ob_statement_1',
    config: {
      step: true,
    },
  },

  ob_fitness_walking: {
    id: 'ob_fitness_walking',
    url: '/ob_fitness_walking',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_pref_eat_place: {
    id: 'ob_pref_eat_place',
    url: '/ob_pref_eat_place',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_feedback_d: {
    id: 'ob_feedback_d',
    url: '/ob_feedback_d',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_better_habits: {
    id: 'ob_better_habits',
    url: '/ob_better_habits',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_feedback_e: {
    id: 'ob_feedback_e',
    url: '/ob_feedback_e',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_feedback_c: {
    id: 'ob_feedback_c',
    url: '/ob_feedback_c',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_feedback_a: {
    id: 'ob_feedback_a',
    url: '/ob_feedback_a',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_feedback: {
    id: 'ob_feedback',
    url: '/ob_feedback',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_result: {
    id: 'ob_result',
    url: '/ob_result',
    config: {
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_motivation: {
    id: 'ob_motivation',
    url: '/ob_motivation',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_life_events: {
    id: 'ob_life_events',
    url: '/ob_life_events',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  bad_habits: {
    id: 'bad_habits',
    url: '/bad_habits',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_fortunately: {
    id: 'ob_fortunately',
    url: '/ob_fortunately',
    config: {
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_childhood_trauma: {
    id: 'ob_childhood_trauma',
    url: '/ob_childhood_trauma',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_trust_feedback: {
    id: 'ob_trust_feedback',
    url: '/ob_trust_feedback',
    config: {
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_sleep: {
    id: 'ob_sleep',
    url: '/ob_sleep',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_creating_3: {
    id: 'ob_creating_3',
    url: '/ob_creating_3',
    config: {
      step: false,
    },
  },

  ob_health_conditions: {
    id: 'ob_health_conditions',
    url: '/ob_health_conditions',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_medications: {
    id: 'ob_medications',
    url: '/ob_medications',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_describes_best: {
    id: 'ob_describes_best',
    url: '/ob_describes_best',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_water: {
    id: 'ob_water',
    url: '/ob_water',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_walking: {
    id: 'ob_walking',
    url: '/ob_walking',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_stairs: {
    id: 'ob_stairs',
    url: '/ob_stairs',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_job_active: {
    id: 'ob_job_active',
    url: '/ob_job_active',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_job_load: {
    id: 'ob_job_load',
    url: '/ob_job_load',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_meals_feel: {
    id: 'ob_meals_feel',
    url: '/ob_meals_feel',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_welcome_to_md: {
    id: 'ob_welcome_to_md',
    url: '/ob_welcome_to_md',
    config: {
      step: false,
    },
  },

  ob_creating_b: {
    id: 'ob_creating_b',
    url: '/ob_creating_b',
    config: {
      step: false,
    },
  },

  ob_time_dinner: {
    id: 'ob_time_dinner',
    url: '/ob_time_dinner',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_time_lunch: {
    id: 'ob_time_lunch',
    url: '/ob_time_lunch',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_time_breakfast: {
    id: 'ob_time_breakfast',
    url: '/ob_time_breakfast',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_happy_weight: {
    id: 'ob_happy_weight',
    url: '/ob_happy_weight',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_target_body_type_a: {
    id: 'ob_target_body_type_a',
    url: '/ob_target_body_type_a',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_goals_mix_b: {
    id: 'ob_goals_mix_b',
    url: '/ob_goals_mix_b',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_gender: {
    id: 'ob_gender',
    url: '/ob_gender',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_social_proof: {
    id: 'ob_social_proof',
    url: '/ob_social_proof',
    config: {
      step: true,
    },
  },

  ob_occasion_result: {
    id: 'ob_occasion_result',
    url: '/ob_occasion_result',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_current_body_type_a: {
    id: 'ob_current_body_type_a',
    url: '/ob_current_body_type_a',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  feedback_summary_fitlevel: {
    id: 'feedback_summary_fitlevel',
    url: '/feedback_summary_fitlevel',
    config: {
      step: false,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_tags: {
    id: 'ob_tags',
    url: '/ob_tags',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_target_zones_b: {
    id: 'ob_target_zones_b',
    url: '/ob_target_zones_b',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_fitness_level_a: {
    id: 'ob_fitness_level_a',
    url: '/ob_fitness_level_a',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_weight_gain_feedback: {
    id: 'ob_weight_gain_feedback',
    url: '/ob_weight_gain_feedback',
    config: {},
  },

  ob_mindea_different: {
    id: 'ob_mindea_different',
    url: '/ob_mindea_different',
    config: {},
  },

  ob_lifestyle: {
    id: 'ob_lifestyle',
    url: '/ob_lifestyle',
    config: {
      step: true,
      questionCategory: QuestionCategory.GOAL,
    },
  },

  ob_weight_gain: {
    id: 'ob_weight_gain',
    url: '/ob_weight_gain',
    config: {
      step: true,
      questionCategory: QuestionCategory.BIO,
    },
  },

  ob_health_issues: {
    id: 'ob_health_issues',
    url: '/ob_health_issues',
    config: {
      step: true,
      questionCategory: QuestionCategory.BIO,
    },
  },

  ob_diets: {
    id: 'ob_diets',
    url: '/ob_diets',
    config: {
      step: true,
      questionCategory: QuestionCategory.BIO,
    },
  },

  ob_funnel_done_thank_you_android: {
    id: 'ob_funnel_done_thank_you_android',
    url: '/ob_funnel_done_thank_you_android',
    config: {},
  },

  ob_feedback_skip_funnel: {
    id: 'ob_feedback_skip_funnel',
    url: '/ob_feedback_skip_funnel',
    config: {
      step: false,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_create_behavior_profile: {
    id: 'ob_create_behavior_profile',
    url: '/ob_create_behavior_profile',
    config: {
      step: false,
      replace: true,
    },
  },

  ob_alt_email_input: {
    id: 'ob_alt_email_input',
    url: '/ob_alt_email_input',
    config: {
      step: false,
    },
  },

  start_food_relationship: {
    id: 'start_food_relationship',
    url: '/start_food_relationship',
    config: {},
  },

  ob_nhe_quiz_external_feedback: {
    id: 'ob_nhe_quiz_external_feedback',
    url: '/ob_nhe_quiz_external_feedback',
    config: {
      step: false,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_nhe_quiz_emotional_feedback: {
    id: 'ob_nhe_quiz_emotional_feedback',
    url: '/ob_nhe_quiz_emotional_feedback',
    config: {
      step: false,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_nhe_quiz_restrained_feedback: {
    id: 'ob_nhe_quiz_restrained_feedback',
    url: '/ob_nhe_quiz_restrained_feedback',
    config: {
      step: false,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_email_consent: {
    id: 'ob_email_consent',
    url: '/ob_email_consent',
    config: {
      step: false,
    },
  },

  ob_weight_graph_nhe: {
    id: 'ob_weight_graph_nhe',
    url: '/ob_weight_graph_nhe',
    config: {
      step: false,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_nutrition_cravings: {
    id: 'ob_nutrition_cravings',
    url: '/ob_nutrition_cravings',
    config: {
      step: true,
      questionCategory: QuestionCategory.NUTRITION,
    },
  },

  ob_feedback_nutrition_restrictive: {
    id: 'ob_feedback_nutrition_restrictive',
    url: '/ob_feedback_nutrition_restrictive',
    config: {
      step: false,
      questionCategory: QuestionCategory.NUTRITION,
    },
  },

  ob_nutrition_hungry_time: {
    id: 'ob_nutrition_hungry_time',
    url: '/ob_nutrition_hungry_time',
    config: {
      step: true,
      questionCategory: QuestionCategory.NUTRITION,
    },
  },

  ob_nutrition_stress_handle: {
    id: 'ob_nutrition_stress_handle',
    url: '/ob_nutrition_stress_handle',
    config: {
      step: true,
      questionCategory: QuestionCategory.NUTRITION,
    },
  },

  ob_nutrition_meal_type: {
    id: 'ob_nutrition_meal_type',
    url: '/ob_nutrition_meal_type',
    config: {
      step: true,
      questionCategory: QuestionCategory.NUTRITION,
    },
  },

  ob_nutrition_eat_habits: {
    id: 'ob_nutrition_eat_habits',
    url: '/ob_nutrition_eat_habits',
    config: {
      step: true,
      questionCategory: QuestionCategory.NUTRITION,
    },
  },

  ob_nutrition_st_fit_wl: {
    id: 'ob_nutrition_st_fit_wl',
    url: '/ob_nutrition_st_fit_wl',
    config: {
      step: true,
      questionCategory: QuestionCategory.NUTRITION,
    },
  },

  ob_behavior_push: {
    id: 'ob_behavior_push',
    url: '/ob_behavior_push',
    config: {
      step: true,
      questionCategory: QuestionCategory.BEHAVIOR_AND_EMOTIONS,
    },
  },

  ob_behavior_guilt: {
    id: 'ob_behavior_guilt',
    url: '/ob_behavior_guilt',
    config: {
      step: true,
      questionCategory: QuestionCategory.BEHAVIOR_AND_EMOTIONS,
    },
  },

  ob_behavior_social: {
    id: 'ob_behavior_social',
    url: '/ob_behavior_social',
    config: {
      step: true,
      questionCategory: QuestionCategory.BEHAVIOR_AND_EMOTIONS,
    },
  },

  ob_behavior_trigger: {
    id: 'ob_behavior_trigger',
    url: '/ob_behavior_trigger',
    config: {
      step: true,
      questionCategory: QuestionCategory.BEHAVIOR_AND_EMOTIONS,
    },
  },

  ob_behavior_st_plate: {
    id: 'ob_behavior_st_plate',
    url: '/ob_behavior_st_plate',
    config: {
      step: true,
      questionCategory: QuestionCategory.BEHAVIOR_AND_EMOTIONS,
    },
  },

  ob_behavior_st_socialize: {
    id: 'ob_behavior_st_socialize',
    url: '/ob_behavior_st_socialize',
    config: {
      step: true,
      questionCategory: QuestionCategory.BEHAVIOR_AND_EMOTIONS,
    },
  },

  ob_bio_sex: {
    id: 'ob_bio_sex',
    url: '/ob_bio_sex',
    config: {
      step: true,
      questionCategory: QuestionCategory.BIO,
    },
  },

  ob_weight_loss_pace: {
    id: 'ob_weight_loss_pace',
    url: '/ob_weight_loss_pace',
    config: {
      step: true,
      questionCategory: QuestionCategory.GOAL,
    },
  },

  ob_weight_loss_focus: {
    id: 'ob_weight_loss_focus',
    url: '/ob_weight_loss_focus',
    config: {
      step: true,
      questionCategory: QuestionCategory.GOAL,
    },
  },

  ob_feedback_program_ready: {
    id: 'ob_feedback_program_ready',
    url: '/ob_feedback_program_ready',
    config: {
      step: false,
    },
  },

  ob_feedback_nutrition_emotions: {
    id: 'ob_feedback_nutrition_emotions',
    url: '/ob_feedback_nutrition_emotions',
    config: {
      step: false,
      questionCategory: QuestionCategory.NUTRITION,
    },
  },

  ob_nutrition_start: {
    id: 'ob_nutrition_start',
    url: '/ob_nutrition_start',
    config: {
      step: false,
      questionCategory: QuestionCategory.NUTRITION,
    },
  },

  ob_nhe_quiz_start: {
    id: 'ob_nhe_quiz_start',
    url: '/ob_nhe_quiz_start',
    config: {
      step: false,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_feedback_behavior_end: {
    id: 'ob_feedback_behavior_end',
    url: '/ob_feedback_behavior_end',
    config: {
      step: false,
      questionCategory: QuestionCategory.BEHAVIOR_AND_EMOTIONS,
    },
  },

  ob_feedback_bio: {
    id: 'ob_feedback_bio',
    url: '/ob_feedback_bio',
    config: {
      step: false,
      questionCategory: QuestionCategory.BEHAVIOR_AND_EMOTIONS,
    },
  },

  ob_feedback_goal_focus: {
    id: 'ob_feedback_goal_focus',
    url: '/ob_feedback_goal_focus',
    config: {
      step: false,
      questionCategory: QuestionCategory.GOAL,
    },
  },

  ob_feedback_goal_set: {
    id: 'ob_feedback_goal_set',
    url: '/ob_feedback_goal_set',
    config: {
      step: false,
      questionCategory: QuestionCategory.GOAL,
    },
  },

  ob_nhe_quiz_external_start: {
    id: 'ob_nhe_quiz_external_start',
    url: '/ob_nhe_quiz_external_start',
    config: {
      step: false,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_nhe_quiz_restrained_start: {
    id: 'ob_nhe_quiz_restrained_start',
    url: '/ob_nhe_quiz_restrained_start',
    config: {
      step: false,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_nhe_quiz_external_10: {
    id: 'ob_nhe_quiz_external_10',
    url: '/ob_nhe_quiz_external_10',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_nhe_quiz_external_9: {
    id: 'ob_nhe_quiz_external_9',
    url: '/ob_nhe_quiz_external_9',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_nhe_quiz_external_8: {
    id: 'ob_nhe_quiz_external_8',
    url: '/ob_nhe_quiz_external_8',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_nhe_quiz_external_7: {
    id: 'ob_nhe_quiz_external_7',
    url: '/ob_nhe_quiz_external_7',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_nhe_quiz_external_6: {
    id: 'ob_nhe_quiz_external_6',
    url: '/ob_nhe_quiz_external_6',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_nhe_quiz_external_5: {
    id: 'ob_nhe_quiz_external_5',
    url: '/ob_nhe_quiz_external_5',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_nhe_quiz_external_4: {
    id: 'ob_nhe_quiz_external_4',
    url: '/ob_nhe_quiz_external_4',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_nhe_quiz_external_3: {
    id: 'ob_nhe_quiz_external_3',
    url: '/ob_nhe_quiz_external_3',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_nhe_quiz_external_2: {
    id: 'ob_nhe_quiz_external_2',
    url: '/ob_nhe_quiz_external_2',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_nhe_quiz_external_1: {
    id: 'ob_nhe_quiz_external_1',
    url: '/ob_nhe_quiz_external_1',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_nhe_quiz_emotional_13: {
    id: 'ob_nhe_quiz_emotional_13',
    url: '/ob_nhe_quiz_emotional_13',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_nhe_quiz_emotional_12: {
    id: 'ob_nhe_quiz_emotional_12',
    url: '/ob_nhe_quiz_emotional_12',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_nhe_quiz_emotional_11: {
    id: 'ob_nhe_quiz_emotional_11',
    url: '/ob_nhe_quiz_emotional_11',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_nhe_quiz_emotional_10: {
    id: 'ob_nhe_quiz_emotional_10',
    url: '/ob_nhe_quiz_emotional_10',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_nhe_quiz_emotional_9: {
    id: 'ob_nhe_quiz_emotional_9',
    url: '/ob_nhe_quiz_emotional_9',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_nhe_quiz_emotional_8: {
    id: 'ob_nhe_quiz_emotional_8',
    url: '/ob_nhe_quiz_emotional_8',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_nhe_quiz_emotional_7: {
    id: 'ob_nhe_quiz_emotional_7',
    url: '/ob_nhe_quiz_emotional_7',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_nhe_quiz_emotional_6: {
    id: 'ob_nhe_quiz_emotional_6',
    url: '/ob_nhe_quiz_emotional_6',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_nhe_quiz_emotional_5: {
    id: 'ob_nhe_quiz_emotional_5',
    url: '/ob_nhe_quiz_emotional_5',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_nhe_quiz_emotional_4: {
    id: 'ob_nhe_quiz_emotional_4',
    url: '/ob_nhe_quiz_emotional_4',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_nhe_quiz_emotional_3: {
    id: 'ob_nhe_quiz_emotional_3',
    url: '/ob_nhe_quiz_emotional_3',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_nhe_quiz_emotional_2: {
    id: 'ob_nhe_quiz_emotional_2',
    url: '/ob_nhe_quiz_emotional_2',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_nhe_quiz_emotional_1: {
    id: 'ob_nhe_quiz_emotional_1',
    url: '/ob_nhe_quiz_emotional_1',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_nhe_quiz_restrained_3: {
    id: 'ob_nhe_quiz_restrained_3',
    url: '/ob_nhe_quiz_restrained_3',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_nhe_quiz_restrained_10: {
    id: 'ob_nhe_quiz_restrained_10',
    url: '/ob_nhe_quiz_restrained_10',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_nhe_quiz_restrained_9: {
    id: 'ob_nhe_quiz_restrained_9',
    url: '/ob_nhe_quiz_restrained_9',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_nhe_quiz_restrained_8: {
    id: 'ob_nhe_quiz_restrained_8',
    url: '/ob_nhe_quiz_restrained_8',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_nhe_quiz_restrained_7: {
    id: 'ob_nhe_quiz_restrained_7',
    url: '/ob_nhe_quiz_restrained_7',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_nhe_quiz_restrained_6: {
    id: 'ob_nhe_quiz_restrained_6',
    url: '/ob_nhe_quiz_restrained_6',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_nhe_quiz_restrained_5: {
    id: 'ob_nhe_quiz_restrained_5',
    url: '/ob_nhe_quiz_restrained_5',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_nhe_quiz_restrained_4: {
    id: 'ob_nhe_quiz_restrained_4',
    url: '/ob_nhe_quiz_restrained_4',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_nhe_quiz_restrained_2: {
    id: 'ob_nhe_quiz_restrained_2',
    url: '/ob_nhe_quiz_restrained_2',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_nhe_quiz_restrained_1: {
    id: 'ob_nhe_quiz_restrained_1',
    url: '/ob_nhe_quiz_restrained_1',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },
  ob_nhe_quiz_result: {
    id: 'ob_nhe_quiz_result',
    url: '/ob_nhe_quiz_result',
    config: {
      step: false,
    },
  },
  ob_create_program_final: {
    id: 'ob_create_program_final',
    url: '/ob_create_program_final',
    config: {
      step: false,
      replace: true,
    },
  },
  ob_feedback_create_program_screens: {
    id: 'ob_feedback_create_program_screens',
    url: '/ob_feedback_create_program_screens',
    config: {
      step: false,
    },
  },
  ob_weight_graph: {
    id: 'ob_weight_graph',
    url: '/ob_weight_graph',
    config: {
      step: false,
    },
  },
  ob_email_input: {
    id: 'ob_email_input',
    url: '/ob_email_input',
    config: {
      step: false,
    },
  },

  ob_bio_height: {
    id: 'ob_bio_height',
    url: '/ob_bio_height',
    config: {
      step: true,
      questionCategory: QuestionCategory.GOAL,
    },
  },
  ob_bio_age: {
    id: 'ob_bio_age',
    url: '/ob_bio_age',
    config: {
      step: true,
      questionCategory: QuestionCategory.BIO,
    },
  },
  ob_weight: {
    id: 'ob_weight',
    url: '/ob_weight',
    config: {
      step: true,
      questionCategory: QuestionCategory.GOAL,
    },
  },
  ob_target_weight: {
    id: 'ob_target_weight',
    url: '/ob_target_weight',
    config: {
      step: true,
      questionCategory: QuestionCategory.GOAL,
    },
  },
  ob_occasion: {
    id: 'ob_occasion',
    url: '/ob_occasion',
    config: {
      step: true,
      questionCategory: QuestionCategory.GOAL,
    },
  },
  ob_feedback_nutrition_eat_all: {
    id: 'ob_feedback_nutrition_eat_all',
    url: '/ob_feedback_nutrition_eat_all',
    config: {
      step: false,
    },
  },
  ob_nhe_quiz_emotional_start: {
    id: 'ob_nhe_quiz_emotional_start',
    url: '/ob_nhe_quiz_emotional_start',
    config: {
      step: false,
    },
  },
  ob_bio_feedback: {
    id: 'ob_bio_feedback',
    url: '/ob_bio_feedback',
    config: {
      step: false,
    },
  },
  ob_feedback_health_goal: {
    id: 'ob_feedback_health_goal',
    url: '/ob_feedback_health_goal',
    config: {
      step: false,
    },
  },

  ob_health_goal_select: {
    id: 'ob_health_goal_select',
    url: '/ob_health_goal_select',
    config: {
      step: true,
      questionCategory: QuestionCategory.GOAL,
    },
  },

  ob_weight_loss_motivation: {
    id: 'ob_weight_loss_motivation',
    url: '/ob_weight_loss_motivation',
    config: {
      step: true,
      questionCategory: QuestionCategory.GOAL,
    },
  },

  // payment screens
  payment_screen: {
    id: 'payment_screen',
    url: '/payment_screen',
    config: {
      replace: true,
      checkoutScreenId: 'checkout_foxtrot',
      motivationScreenId: 'payment_exit_intent',
    },
  },

  payment_screen_2: {
    id: 'payment_screen_2',
    url: '/payment_screen_2',
    config: {
      replace: true,
      checkoutScreenId: 'checkout_foxtrot',
      motivationScreenId: 'payment_exit_intent',
    },
  },

  payment_base: {
    id: 'payment_base',
    url: '/payment_base',
    config: {
      replace: true,
      checkoutScreenId: 'checkout_golf',
      motivationScreenId: 'payment_exit_intent',
    },
  },

  payment_base_copy: {
    id: 'payment_base_copy',
    url: '/payment_base_copy',
    config: {
      replace: true,
      checkoutScreenId: 'checkout_golf',
      motivationScreenId: 'payment_exit_intent',
    },
  },

  payment_chakra: {
    id: 'payment_chakra',
    url: '/payment_chakra',
    config: {
      replace: true,
      checkoutScreenId: 'checkout_golf',
      motivationScreenId: 'payment_exit_intent',
    },
  },

  payment_adhd: {
    id: 'payment_adhd',
    url: '/payment_adhd',
    config: {
      replace: true,
      checkoutScreenId: 'checkout_golf',
      motivationScreenId: 'payment_exit_intent',
    },
  },

  payment_discount: {
    id: 'payment_discount',
    url: '/payment_discount',
    config: {
      replace: true,
      checkoutScreenId: 'checkout_foxtrot',
      motivationScreenId: 'payment_exit_intent',
    },
  },

  offer_a5: {
    id: 'offer_a5',
    url: '/offer_a5',
    config: {
      replace: true,
      checkoutScreenId: 'checkout_golf',
      motivationScreenId: 'payment_exit_intent',
    },
  },

  upsell_1: {
    id: 'upsell_1',
    url: '/upsell_1',
    config: {
      replace: true,
      checkoutScreenId: 'checkout_golf',
      motivationScreenId: 'payment_exit_intent',
    },
  },

  // post checkout screens

  ob_payment_success_register: {
    id: 'ob_payment_success_register',
    url: '/ob_payment_success_register',
    config: {
      step: false,
      replace: true,
    },
  },

  ob_funnel_done_thank_you: {
    id: 'ob_funnel_done_thank_you',
    url: '/ob_funnel_done_thank_you',
    config: {
      step: false,
    },
  },
}
