import { useEffect, useMemo } from 'react'

import { usePostUsersFunnelProfile } from 'shared/api/forerunner'
import { BaseScreen } from 'shared/api/testania'
import { useAuthUser } from 'shared/providers/AuthUserProvider'
import { useClientData } from 'shared/providers/ClientDataProvider'
import { logger } from 'shared/utils/logger'
import { clientToBackendProperties } from 'shared/utils/normalizeUserProperties'

import { useDEBQQuizResults } from './useDEBQQuizResults'

export const useUpdateUserOnLastScreen = (
  onboardingFlow?: BaseScreen[],
  currentScreenId?: string
) => {
  const { state } = useClientData()
  const { eatingProfile } = useDEBQQuizResults()
  const {
    operations: { updateUser },
  } = useAuthUser()

  const { mutateAsync: patchUser } = usePostUsersFunnelProfile()

  const lastOnboardingScreen = useMemo(() => onboardingFlow?.at(-1), [onboardingFlow])

  useEffect(() => {
    const isLastOnboardingScreen = lastOnboardingScreen?.id === currentScreenId
    const isEmailScreen = currentScreenId?.includes('email')

    if (!onboardingFlow || !currentScreenId || !isLastOnboardingScreen || isEmailScreen) return

    const userProperties = clientToBackendProperties(state, eatingProfile)

    patchUser(
      { data: userProperties },
      {
        onSuccess(response) {
          updateUser(response)
        },
        onError(err: unknown) {
          logger.error(new Error('Failed to update user on last screen', { cause: err }))
        },
      }
    )
  }, [
    currentScreenId,
    eatingProfile,
    lastOnboardingScreen?.id,
    onboardingFlow,
    patchUser,
    state,
    updateUser,
  ])
}
