// Auto-generated file created by svgr-core
// Run npm run svg:generate to update
import React, { SVGProps } from 'react'
const IconProfileGuardianWhite = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} fill="none" {...props}>
      <g filter="url(#prefix__a)">
        <path
          fill="#fff"
          d="M5.656 22.875V11.312c0-2.234.735-3.14 2.688-3.968 1.531-.64 7.234-2.625 8.672-3.032.687-.187 1.53-.359 2.203-.359.672 0 1.515.14 2.203.36 1.453.468 7.14 2.375 8.687 3.03 1.938.829 2.688 1.735 2.688 3.97v11.562c0 5.688-3.25 8.172-12.39 12.938-.423.203-.876.343-1.188.343-.313 0-.766-.14-1.172-.343-9.156-4.782-12.39-7.25-12.39-12.938Zm13.735 9.156c.156-.031.312-.11.609-.281 6.797-4.047 9.063-5.234 9.063-9.469V11.828c0-.594-.141-.844-.579-1.015C26.25 9.983 22 8.515 20.375 7.905c-.469-.156-.719-.218-.984-.234v24.36Z"
        />
      </g>
      <defs>
        <filter
          id="prefix__a"
          width={27.141}
          height={33.203}
          x={5.656}
          y={3.953}
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={1} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1626_7587" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_1626_7587" result="shape" />
        </filter>
      </defs>
    </svg>
  )
}
export { IconProfileGuardianWhite }
